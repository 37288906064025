import React from 'react';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";

import ReduxWrapper from "../redux/store";
import theme from "./theme";
import Router from "./router";

export default function App() {

    return (
        <ReduxWrapper>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Router/>
                </ThemeProvider>
            </StyledEngineProvider>
        </ReduxWrapper>
    );
}
