export const SET_DATA = "SET_DATA";
export const SET_KINGDOM = "SET_KINGDOM";
export const SET_KINGDOM_PROPERTY = "SET_KINGDOM_PROPERTY";
export const SET_KINGDOM_ATTRIBUTE = "SET_KINGDOM_ATTRIBUTE";
export const SET_RANK = "SET_RANK";
export const SET_RANK_ATTRIBUTE = "SET_RANK_ATTRIBUTE";
export const SET_RANK_PROPERTY = "SET_RANK_PROPERTY";
export const DELETE_KINGDOM = "DELETE_KINGDOM";
export const DELETE_RANK = "DELETE_RANK";


function main(state, action) {

    if ( action.type !== SET_DATA && action.type !== SET_KINGDOM
        && action.type !== SET_KINGDOM_ATTRIBUTE && action.type !== SET_RANK
        && action.type !== SET_RANK_ATTRIBUTE && action.type !== SET_KINGDOM_PROPERTY
        && action.type !== SET_RANK_PROPERTY && action.type !== DELETE_KINGDOM
        && action.type !== DELETE_RANK) {
        return state;
    }

    const newState = Object.assign({}, state);
    newState.data = Object.assign({}, state.data);

    if ( action.type === SET_DATA ) {
        newState.data = action.data;
        return newState;
    }

    newState.data.kingdoms = Object.assign({}, newState.data.kingdoms);

    if ( action.type === SET_KINGDOM ) {
        newState.data.kingdoms[action.kingdom] = action.data;
        return newState;
    }

    if ( action.type === DELETE_KINGDOM ) {
        newState.data.deleted_kingdoms = newState.data.deleted_kingdoms ? [...newState.data.deleted_kingdoms] : []
        newState.data.deleted_kingdoms.push(action.kingdom);
        delete newState.data.kingdoms[action.kingdom];
        return newState;
    }

    if ( action.type === SET_KINGDOM_ATTRIBUTE ) {
        newState.data.kingdoms[action.kingdom].attributes = Object.assign({}, newState.data.kingdoms[action.kingdom].attributes);
        newState.data.kingdoms[action.kingdom].attributes[action.attribute] = action.data;
        return newState;
    }

    if ( action.type === SET_KINGDOM_PROPERTY ) {
        newState.data.kingdoms[action.kingdom][action.property] = action.data;
        return newState;
    }

    newState.data.kingdoms[action.kingdom].ranks = Object.assign({}, newState.data.kingdoms[action.kingdom].ranks);

    if ( action.type === SET_RANK ) {
        newState.data.kingdoms[action.kingdom].ranks[action.rank] = action.data;
        return newState;
    }

    if ( action.type === DELETE_RANK ) {
        if ( newState.data.kingdoms[action.kingdom].defaultrank === action.rank ) {
            newState.data.kingdoms[action.kingdom].defaultrank = "";
        }
        newState.data.kingdoms[action.kingdom].deleted_ranks = newState.data.kingdoms[action.kingdom].deleted_ranks ? [...newState.data.kingdoms[action.kingdom].deleted_ranks] : []
        newState.data.kingdoms[action.kingdom].deleted_ranks.push(action.rank);
        delete newState.data.kingdoms[action.kingdom].ranks[action.rank];
        return newState;
    }

    newState.data.kingdoms[action.kingdom].ranks[action.rank].attributes = Object.assign({}, newState.data.kingdoms[action.kingdom].ranks[action.rank].attributes);

    if ( action.type === SET_RANK_ATTRIBUTE ) {
        newState.data.kingdoms[action.kingdom].ranks[action.rank].attributes[action.attribute] = action.data;
        return newState;
    }

    if ( action.type === SET_RANK_PROPERTY ) {
        newState.data.kingdoms[action.kingdom].ranks[action.rank][action.property] = action.data;
        return newState;
    }

    return state;
}

export default function reduce(state, action) {
    return main(state, action);
}
