import React from "react";
import {default as MuiTextField} from "@mui/material/TextField";
import FieldLayout from "./FieldLayout";

export default function NumberField({ name, value, helpUrl, onChange }) {

    function onChangeInternal(val) {
        if ( val === "" ) {
            onChange(val);
        }

        if ( val.length > 5 ) {
            return;
        }

        try {
            const num = parseInt(val);
            onChange(num);
        } catch (e) {}
    }

    return (
        <FieldLayout helpUrl={helpUrl} name={name}>
            <MuiTextField
                fullWidth
                value={value}
                onChange={(e) => onChangeInternal(e.target.value)}
                variant="outlined"
            />
        </FieldLayout>
    );
}
