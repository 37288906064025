import React from "react";

import TextField from "../fields/textField";

export default function OldAttributes({ attributes, data, set_attribute }) {

    const elements = [];
    const array = Object.keys(attributes);
    for ( let i = 0; i < array.length; i++ ) {
        const key = array[i];
        const name = attributes[key];
        elements.push(<TextField name={name} key={i}
                                 value={ data.attributes == null || data.attributes[key] == null ? "" : data.attributes[key]}
                                 onChange={(value) => set_attribute(key, value)}/>)
    }
    return elements;
}