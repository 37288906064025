import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import {delete_kingdom} from "../../redux/actions";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

export default function DeleteKingdomDialog({ kingdom, open, onClose }) {

    const dispatch = useDispatch();

    const [deleteSnackbar, setDeleteSnackbar] = useState(null);

    function onDeleteKingdom() {
        setDeleteSnackbar(
            <Alert onClose={() => setDeleteSnackbar(null)} severity="success">
                Deleted the kingdom {kingdom}!
            </Alert>
        );

        dispatch(delete_kingdom(kingdom));
        onClose();
    }

    if ( !open ) {
        return null;
    }

    return (
        <>
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>Delete kingdom</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete <strong>{kingdom}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onDeleteKingdom}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={!!deleteSnackbar} autoHideDuration={6000} onClose={() => setDeleteSnackbar(null)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                {deleteSnackbar}
            </Snackbar>
        </>
    )
}
