import React from "react";

import NumberField from "../fields/numberField";
import TextField from "../fields/textField";
import ColorField from "../fields/colorField";

export default function Attributes({ attributes, data, set_attribute }) {
    const groupless = [];
    const groups = {};
    for ( const attribute of Object.values(attributes) ) {
        if ( !attribute.group ) {
            groupless.push(attribute);
            continue;
        }

        if ( !groups[attribute.group] ) {
            groups[attribute.group] = [];
        }
        groups[attribute.group].push(attribute);
    }

    for ( const group of Object.keys(groups).sort((a, b) => a.localeCompare(b)) ) {
        for ( const attribute of groups[group] ) {
            groupless.push(attribute);
        }
    }

    const elements = [];
    for ( let i = 0; i < groupless.length; i++ ) {
        const attribute = groupless[i];
        const key = attribute.key;
        const props = {
            name: attribute.display,
            value: data.attributes && data.attributes[key] ? data.attributes[key] : "",
            helpUrl: attribute.helpLink ? attribute.helpLink : null,
            onChange: (value) => set_attribute(key, value)
        }

        if ( attribute.type === "INTEGER" ) {
            elements.push(<NumberField key={i} {...props}/>);
        } else if ( attribute.type === "COLOR" ) {
            elements.push(<ColorField key={i} {...props}/>);
        } else {
            elements.push(<TextField key={i} {...props}/>);
        }
    }
    return elements;
}
