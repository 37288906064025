import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: "#B38724",
        },
        secondary: {
            main: "#FFD166",
            dark: "#c8a451"
        },
    },
});