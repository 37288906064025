import React from "react";
import clsx from "clsx";
import {useSelector} from "react-redux";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";

import style from "./sidebar.module.scss";

export default function Sidebar({ selectedKingdom, selectedRank, onSelectKingdom, onSelectRank }) {

    const kingdoms = useSelector(state => state.data.kingdoms);

    return (
        <aside className={style.root}>
            <div className={style.sidebar}>
                {kingdoms["template"] ? (
                        <SidebarKingdom name={"template"} kingdom={kingdoms["template"]}
                                        selectedKingdom={selectedKingdom} selectedRank={selectedRank}
                                        onSelect={() => onSelectKingdom("template")}
                                        onSelectRank={(rank) => onSelectRank("template", rank)}/>
                ) : null}

                <>
                    {Object.keys(kingdoms).filter(k => k !== "template").map((name, i) => {
                        return <SidebarKingdom key={i} name={name} kingdom={kingdoms[name]}
                                               selectedKingdom={selectedKingdom} selectedRank={selectedRank}
                                               onSelect={() => onSelectKingdom(name)}
                                               onSelectRank={(rank) => onSelectRank(name, rank)}/>
                    })}
                </>
            </div>
        </aside>
    )
}

function SidebarKingdom({ name, kingdom, selectedKingdom, selectedRank, onSelect, onSelectRank }) {

    const [open, setOpen] = React.useState(true);

    function toggle(e) {
        e.stopPropagation();
        e.preventDefault();

        setOpen(!open);
    }

    function select() {
        setOpen(true);
        onSelect();
    }

    function selectRank(rank) {
        onSelectRank(rank);
    }

    const active = name === selectedKingdom;

    if ( !kingdom.ranks ) {
        return (
            <ListItem button onClick={select} className={clsx(name === "template" && style.template, active && style.active)}>
                <ListItemText primary={(<strong>{name}</strong>)} />
            </ListItem>
        )
    }

    return <>
        <ListItem button onClick={select} className={clsx(name === "template" && style.template, active && style.active)}>
            <ListItemText primary={(<strong>{name}</strong>)} />
            <IconButton onClick={toggle} size={"medium"} sx={{width: "43px"}}>
                {open ? (
                    <span key={1}><i className="fas fa-angle-up" /></span>
                ) : (
                    <span key={2}><i className="fas fa-angle-down"/></span>
                )}
            </IconButton>
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={style.subList}>
                {Object.keys(kingdom.ranks).map((name, i) => {
                    return (
                        <ListItem button key={i} onClick={() => selectRank(name)} className={clsx(active && name === selectedRank && style.active)}>
                            <ListItemText primary={name}/>
                        </ListItem>
                    );
                })}
            </List>
            <br/>
        </Collapse>
    </>;
}
