import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React, {useEffect, useState} from "react";

import style from "./exportDialog.module.scss";

export default function ExportDialog({ value, onClose }) {

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if ( !copied ) {
            return;
        }

        const id = setTimeout(() => {
            setCopied(false);
        }, 1500);
        return () => {
            clearTimeout(id);
        }
    }, [copied]);

    function onCopy() {
        const text = "/kingdomcraft editor " + value;

        try {
            return navigator.clipboard.writeText(text)
        } catch (ignored) {
            const textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            console.log("copied: ", document.execCommand('copy'));
            document.body.removeChild(textArea);

            setCopied(true);
        }
    }

    return (
        <Dialog onClose={onClose} open={true} disableEscapeKeyDown disableAutoFocus>
            <DialogTitle>Apply changes</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Use following command to apply the changes.
                </DialogContentText>
                <OutlinedInput
                    type={'text'}
                    value={"/kingdomcraft editor " + value}
                    disabled
                    className={style.input}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title={copied ? "Copied!" : "Copy"} placement="top">
                                <IconButton onClick={onCopy} onMouseDown={(e) => e.preventDefault()} size="large">
                                            <span className={style.faIcon}>
                                                <i className="far fa-clone"/>
                                            </span>
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
