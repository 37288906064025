import React from "react";
import {useDispatch, useSelector} from "react-redux";

import Typography from "@mui/material/Typography";

import {set_rank_attribute, set_rank_property} from "../../redux/actions";

import TextField from "./fields/textField";
import NumberField from "./fields/numberField";

import OldAttributes from "./attributes/OldAttributes";
import Attributes from "./attributes/Attributes";

export default function RankEditor({kingdom, rank, data}) {

    const dispatch = useDispatch();
    const rankAttributes = useSelector(state => state.data.rankAttributes);

    let attributes = null;
    if (rankAttributes != null && Object.keys(rankAttributes).length > 0) {
        const first = Object.keys(rankAttributes)[0];
        if (rankAttributes[first] instanceof String) {
            attributes = <OldAttributes attributes={rankAttributes} data={data}
                                        set_attribute={(key, val) => dispatch(set_rank_attribute(kingdom, rank, key, val))}
            />
        } else {
            attributes = <Attributes attributes={rankAttributes} data={data}
                                     set_attribute={(key, val) => dispatch(set_rank_attribute(kingdom, rank, key, val))}
            />
        }
    }

    return (
        <>
            <Typography component={"h1"} variant={"h4"}>
                Edit rank
            </Typography>
            <br/>

            <TextField name={"Display"} helpUrl={"/starter/ranks#display"}
                       value={data.display ? data.display : ""}
                       onChange={(value) => dispatch(set_rank_property(kingdom, rank, "display", value))}/>

            <TextField name={"Prefix"} helpUrl={"/starter/ranks#prefix"}
                       value={data.prefix ? data.prefix : ""}
                       onChange={(value) => dispatch(set_rank_property(kingdom, rank, "prefix", value))}/>

            <TextField name={"Suffix"} helpUrl={"/starter/ranks#suffix"}
                       value={data.suffix ? data.suffix : ""}
                       onChange={(value) => dispatch(set_rank_property(kingdom, rank, "suffix", value))}/>

            <NumberField name={"Max. members"} helpUrl={"/starter/ranks#max-members"}
                         value={data["max-members"] ? data["max-members"] : ""}
                         onChange={(value) => dispatch(set_rank_property(kingdom, rank, "max-members", value))}/>

            <NumberField name={"Level"} helpUrl={"/starter/ranks#level"}
                         value={data.level ? data.level : ""}
                         onChange={(value) => dispatch(set_rank_property(kingdom, rank, "level", value))}/>

            {attributes}
        </>
    )
}