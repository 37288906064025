import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Alert from '@mui/material/Alert';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {set_rank} from "../../redux/actions";

export default function CloneRanksDialog({kingdom, open, onClose, onClone}) {

    const dispatch = useDispatch();
    const kingdoms = useSelector(state => state.data.kingdoms);

    const [cloneRankSnackbar, setCloneRankSnackbar] = useState(null);

    const [selectedKingdoms, setSelectedKingdoms] = useState([]);
    const [selectedRanks, setSelectedRanks] = useState([]);

    if ( !kingdom ) {
        return null;
    }

    function closeCloneRanks() {
        setSelectedKingdoms([]);
        setSelectedRanks([]);
        onClose();
    }

    function onCloneRanks() {
        if ( selectedKingdoms.length === 0 || selectedRanks.length === 0) {
            return;
        }

        setCloneRankSnackbar(
            <Alert onClose={() => setCloneRankSnackbar(null)} severity="success">
                Cloned {selectedRanks.length} {selectedRanks.length === 1 ? "rank" : "ranks"} from <strong>{kingdom}</strong> to <strong>{selectedKingdoms.length}</strong> kingdoms!
            </Alert>
        )

        for ( let target of selectedKingdoms ) {
            for (let rank of selectedRanks) {
                dispatch(set_rank(target, rank, JSON.parse(JSON.stringify(kingdoms[kingdom].ranks[rank]))));
            }
        }

        onClone(selectedKingdoms[0]);
        closeCloneRanks()
    }

    function toggleRank(rank) {
        setSelectedRanks(toggle(selectedRanks, rank));
    }

    function toggleKingdom(kingdom) {
        setSelectedKingdoms(toggle(selectedKingdoms, kingdom));
    }

    function toggle(array, value) {
        const index = array.indexOf(value);
        if ( index === -1 ) {
            return [...array, value];
        }

        array.splice(index, 1);
        return [...array];
    }

    function toggleAllKingdoms() {
        if ( selectedKingdoms.length === Object.keys(kingdoms).length-1 ) {
            setSelectedKingdoms([]);
            return;
        }

        const result = [];
        for ( let kd of Object.keys(kingdoms) ) {
            if ( kd !== kingdom ) {
                result.push(kd);
            }
        }
        setSelectedKingdoms(result);
    }

    function toggleAllRanks() {
        if ( selectedRanks.length === Object.keys(kingdoms[kingdom].ranks).length ) {
            setSelectedRanks([]);
            return;
        }

        setSelectedRanks([...Object.keys(kingdoms[kingdom].ranks)]);
    }

    return (
        <>
            <Dialog onClose={closeCloneRanks} open={open}>
                <DialogTitle>Copy ranks</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the target kingdoms
                    </DialogContentText>
                    <Grid container>
                        <FormControlLabel label={"All"}
                                          control={<Checkbox color={"primary"} checked={selectedKingdoms.length === Object.keys(kingdoms).length-1} onChange={() => toggleAllKingdoms()} />}
                        />
                        {Object.keys(kingdoms).map((val, i) => {
                            if ( val === kingdom ) {
                                return null;
                            }
                            return (
                                <Grid item key={i}>
                                    <FormControlLabel label={val}
                                                      control={<Checkbox checked={selectedKingdoms.indexOf(val) !== -1} onChange={() => toggleKingdom(val)} />}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <br/><br/>

                    <DialogContentText>
                        Select the ranks to copy
                    </DialogContentText>
                    <Grid container>
                        <FormControlLabel label={"All"}
                                          control={<Checkbox color={"primary"} checked={kingdoms[kingdom].ranks && selectedRanks.length === Object.keys(kingdoms[kingdom].ranks).length} onChange={() => toggleAllRanks()} />}
                        />
                        {kingdoms[kingdom].ranks ? Object.keys(kingdoms[kingdom].ranks).map((rank, i) => {
                            return (
                                <Grid item key={i}>
                                    <FormControlLabel label={rank}
                                        control={<Checkbox checked={selectedRanks.indexOf(rank) !== -1} onChange={() => toggleRank(rank)} />}
                                    />
                                </Grid>
                            )
                        }) : null}
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCloneRanks} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onCloneRanks} color="primary" disabled={selectedKingdoms.length === 0 || selectedRanks.length === 0}>
                        Clone
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={!!cloneRankSnackbar} autoHideDuration={6000}
                      onClose={() => setCloneRankSnackbar(null)} anchorOrigin={{vertical: "top", horizontal: "center"}}>
                {cloneRankSnackbar}
            </Snackbar>
        </>
    )
}
