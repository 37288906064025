import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FieldLayout from "./FieldLayout";

export default function SelectField({ name, value, values, helpUrl, onChange }) {

    return (
        <FieldLayout helpUrl={helpUrl} name={name}>
            <FormControl variant="outlined" fullWidth>
                <Select
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={values.length === 0}
                >
                    { values.map((val, i) => {
                        return <MenuItem value={val} key={i}>{val}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </FieldLayout>
    );
}
