import React, {useState} from 'react';
import {useSelector} from "react-redux";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import Sidebar from "../sidebar/sidebar";
import RankEditor from "../editorForms/rankEditForm";
import KingdomEditForm from "../editorForms/kingdomEditForm";
import CreateKingdomDialog from "../createDialogs/createKingdomDialog";
import CreateRankDialog from "../createDialogs/createRankDialog";
import DeleteKingdomDialog from "../deleteDialogs/deleteKingdomDialog";
import DeleteRankDialog from "../deleteDialogs/deleteRankDialog";

import style from "./editor.module.scss";
import CloneRankDialog from "../cloneDialogs/cloneRankDialog";
import CloneRanksDialog from "../cloneDialogs/cloneRanksDialog";

export default function Editor() {

    const data = useSelector(state => state.data);

    const [selectedKingdom, setSelectedKingdom] = useState(null);
    const [selectedRank, setSelectedRank] = useState(null);

    const [openCreateKingdomDialog, setOpenCreateKingdomDialog] = useState(false);
    const [openCreateRankDialog, setOpenCreateRankDialog] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCloneDialog, setOpenCloneDialog] = useState(false);
    const [openCloneMultiDialog, setOpenCloneMultiDialog] = useState(false);

    if (data == null) {
        return null;
    }

    if (Object.keys(data.kingdoms).length === 0) {
        return (
            <>
                <div className={style.textCenter}>
                    <br/><br/>
                    <Typography variant={"h4"} component={"h2"}>
                        Create your first kingdom
                    </Typography>
                    <br/>


                    <Button variant={"contained"} color={"primary"} onClick={() => setOpenCreateKingdomDialog(true)}>
                        Create
                    </Button>
                </div>

                <CreateKingdomDialog open={openCreateKingdomDialog}
                                     onClose={() => setOpenCreateKingdomDialog(false)}
                                     onCreate={(kingdom) => setSelectedKingdom(kingdom)}/>
            </>
        )
    }

    if (selectedKingdom != null) {
        if (data.kingdoms[selectedKingdom] == null) {
            setSelectedKingdom(null);
            setSelectedRank(null);
            return null;
        } else if (selectedRank != null && data.kingdoms[selectedKingdom].ranks[selectedRank] == null) {
            setSelectedRank(null);
            return null;
        }
    } else if (data.kingdoms) {
        const kingdoms = Object.keys(data.kingdoms);
        if (kingdoms.length > 0) {
            setSelectedKingdom(kingdoms[0]);
            return null;
        }
        // select
    }

    function onSelectKingdom(kingdom) {
        setSelectedKingdom(kingdom);
        setSelectedRank(null);
    }

    function onSelectRank(kingdom, rank) {
        setSelectedKingdom(kingdom);
        setSelectedRank(rank);
    }

    return (
        <>
            <Container maxWidth={"lg"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <div className={style.header}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <Typography component={"h3"} variant={"h5"}>
                                        {selectedKingdom && selectedRank ? (
                                            <>
                                                <span>{selectedKingdom}</span>
                                                &nbsp;&nbsp;
                                                <span className={style.headerSub}><i className="fas fa-angle-right"/></span>
                                                &nbsp;&nbsp;
                                                <span>{selectedRank}</span>
                                            </>
                                        ) : selectedKingdom ?
                                            selectedKingdom
                                        : null}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} className={style.textRight}>
                                    <Button color={"secondary"} variant={"contained"}
                                            startIcon={<i className="fas fa-plus"/>}
                                            onClick={() => setOpenCreateRankDialog(true)}>
                                        Add rank
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <div className={style.header}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography component={"h3"} variant={"h5"}>
                                        Kingdoms
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} className={style.textRight}>
                                    <Button color={"primary"} variant={"contained"}
                                            onClick={() => setOpenCreateKingdomDialog(true)}>
                                        &nbsp;<i className="fas fa-plus"/>&nbsp;
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <br/>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper square className={style.paper}>

                            {/* FORM */}
                            {selectedKingdom && selectedRank ? (
                                <RankEditor kingdom={selectedKingdom} rank={selectedRank}
                                            data={data.kingdoms[selectedKingdom].ranks[selectedRank]}/>
                            ) : selectedKingdom ? (
                                <KingdomEditForm kingdom={selectedKingdom} data={data.kingdoms[selectedKingdom]}/>
                            ) : null}

                            {/* ACTIONS */}
                            <br/>
                            <Divider/>
                            <br/>

                            { selectedKingdom && selectedRank ? (
                                <>
                                    <Button variant={"contained"} color={"primary"}
                                            startIcon={<i className="far fa-clone"/>}
                                            onClick={() => setOpenCloneDialog(true)}>
                                        Copy rank
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button variant={"outlined"} className={style.deleteButton}
                                            startIcon={<i className="fas fa-trash"/>}
                                            onClick={() => setOpenDeleteDialog(true)}>
                                        Delete rank
                                    </Button>
                                </>
                            ) : selectedKingdom ? (
                                <>
                                    <Button variant={"contained"} color={"primary"}
                                            disabled={!data.kingdoms[selectedKingdom].ranks || Object.keys(data.kingdoms[selectedKingdom].ranks).length === 0}
                                            startIcon={<i className="far fa-clone"/>}
                                            onClick={() => setOpenCloneMultiDialog(true)}>
                                        Copy ranks
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button variant={"outlined"} className={style.deleteButton}
                                            startIcon={<i className="fas fa-trash"/>}
                                            onClick={() => setOpenDeleteDialog(true)}
                                            disabled={selectedKingdom === "template"}
                                    >
                                        Delete kingdom
                                    </Button>
                                </>
                            ) : null }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Sidebar onSelectKingdom={onSelectKingdom} onSelectRank={onSelectRank} selectedKingdom={selectedKingdom} selectedRank={selectedRank}/>
                    </Grid>
                </Grid>
            </Container>

            <CreateKingdomDialog open={openCreateKingdomDialog}
                                 onClose={() => setOpenCreateKingdomDialog(false)}
                                 onCreate={(kingdom) => onSelectKingdom(kingdom)}/>

            <CreateRankDialog kingdom={selectedKingdom} open={openCreateRankDialog}
                              onClose={() => setOpenCreateRankDialog(false)}
                              onCreate={(kingdom, rank) => onSelectRank(kingdom, rank)}/>

            <DeleteKingdomDialog kingdom={selectedKingdom}
                                 open={openDeleteDialog && !!selectedKingdom && !selectedRank}
                                 onClose={() => setOpenDeleteDialog(false)}/>

            <DeleteRankDialog kingdom={selectedKingdom} rank={selectedRank}
                              open={openDeleteDialog && !!selectedKingdom && !!selectedRank}
                              onClose={() => setOpenDeleteDialog(false)}/>

            <CloneRankDialog kingdom={selectedKingdom} rank={selectedRank}
                             open={(openCloneDialog && !!selectedKingdom && !!selectedRank)}
                             onClose={() => setOpenCloneDialog(false)}
                             onClone={(kingdom) => setSelectedKingdom(kingdom)}/>

             <CloneRanksDialog kingdom={selectedKingdom}
                               open={(openCloneMultiDialog && !!selectedKingdom)}
                               onClose={() => setOpenCloneMultiDialog(false)}
                               onClone={(kingdom) => setSelectedKingdom(kingdom)}/>
        </>
    )
}
