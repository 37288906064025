import React from "react";
import Grid from "@mui/material/Grid";

import style from "../editForm.module.scss";

export default function FieldLayout({helpUrl, name, children}) {
    return (
        <Grid container spacing={1} className={style.field}>
            <Grid item xs={12} sm={3}>
                <div className={style.vc}>
                    <div className={style.vcc}>
                        {helpUrl && (
                            <a className={style.helpCircle}
                               href={process.env.REACT_APP_HELP_URI + helpUrl}
                               target={"_blank"} rel="noreferrer noopenr">
                                <i className="far fa-question-circle"/>
                            </a>
                        )}

                        <strong>{name}</strong>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={9}>
                {children}
            </Grid>
        </Grid>
    );
}