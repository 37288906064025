import React, {useState} from "react";
import FieldLayout from "./FieldLayout";
import {BlockPicker} from "react-color";
import {Backdrop} from "@mui/material";

const defaultColors = [
    '#a00', '#f55', '#0a0', '#5f5', '#00a',
    '#55a', '#fa0', '#5ff', '#ff5', '#f5f',
    '#a0a', '#0aa', '#aaa', '#555', '#000'
]

export default function ColorField({name, value, helpUrl, onChange}) {

    const [open, setOpen] = useState(false);

    return (
        <FieldLayout helpUrl={helpUrl} name={name}>
            <div style={{position: "relative"}}>
                <div style={{
                    background: value,
                    height: "30px",
                    margin: "13px 0",
                    maxWidth: "170px",
                    border: "2px solid " + (value ? "#000" : "#bbb"),
                    cursor: "pointer",
                    borderRadius: "5px"
                }} onClick={() => setOpen(true)}>
                    &nbsp;
                </div>

                {open && (
                    <>
                        <Backdrop open invisible onClick={() => setOpen(false)} sx={{zIndex: "999"}}/>
                        <div style={{position: "absolute", background: "#fff", paddingBottom: "20px", zIndex: "1000"}}>
                            <BlockPicker color={value} colors={defaultColors} onChangeComplete={(color) => onChange(color.hex)}/>
                        </div>
                    </>
                )}
            </div>
        </FieldLayout>
    );
}
