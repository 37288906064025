import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Alert from '@mui/material/Alert';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import {set_kingdom} from "../../redux/actions";

export default function CreateKingdomDialog({open, onClose, onCreate}) {

    const dispatch = useDispatch();
    const kingdoms = useSelector(state => state.data.kingdoms);

    const [createKingdomSnackbar, setCreateKingdomSnackbar] = useState(null);
    const [createKingdomName, setCreateKingdomName] = useState("");

    function closeCreateKingdom() {
        setCreateKingdomName("");
        onClose();
    }

    function onCreateKingdom(e) {
        e.preventDefault();
        for (let kingdom of Object.keys(kingdoms)) {
            if (kingdom.toLowerCase() === createKingdomName.toLowerCase()) {
                setCreateKingdomSnackbar(
                    <Alert onClose={() => setCreateKingdomSnackbar(null)} severity="error">
                        A kingdom with that name already exists!
                    </Alert>
                )
                return;
            }
        }

        setCreateKingdomSnackbar(
            <Alert onClose={() => setCreateKingdomSnackbar(null)} severity="success">
                Created kingdom {createKingdomName}!
            </Alert>
        );

        let result = {};
        if (kingdoms["template"]) {
            result = JSON.parse(JSON.stringify(kingdoms["template"]));
            if (result.display) {
                result.display = result.display.replace("{kingdom_name}", createKingdomName);
            }
            if (result.prefix) {
                result.prefix = result.prefix.replace("{kingdom_name}", createKingdomName);
            }
            if (result.suffix) {
                result.suffix = result.suffix.replace("{kingdom_name}", createKingdomName);
            }
        }

        dispatch(set_kingdom(createKingdomName, result));
        onCreate(createKingdomName);
        closeCreateKingdom();
    }

    return <>
        <Dialog onClose={closeCreateKingdom} open={open}>
            <DialogTitle>Create kingdom</DialogTitle>
            <DialogContent>
                <form onSubmit={onCreateKingdom}>
                    <DialogContentText>
                        Enter the kingdom's name.
                    </DialogContentText>
                    <TextField autoFocus margin="dense" type="text" variant={"outlined"} fullWidth
                               value={createKingdomName}
                               onChange={(e) => setCreateKingdomName(e.target.value.replace(/[^0-9a-z]/gi, ""))}/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeCreateKingdom} color="primary">
                    Cancel
                </Button>
                <Button onClick={onCreateKingdom} color="primary" disabled={createKingdomName === ""}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={!!createKingdomSnackbar} autoHideDuration={6000} onClose={() => setCreateKingdomSnackbar(null)}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            {createKingdomSnackbar}
        </Snackbar>
    </>;
}
