import React from 'react';

import Main from "../components/main/main";
import {BrowserRouter, Route, Routes} from "react-router-dom";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/:id"} element={<Main/>}/>
                <Route exact path={"/"} element={<Main/>}/>
            </Routes>
        </BrowserRouter>
    );
}
