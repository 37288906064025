import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import {set_data} from "../../redux/actions";
import ExportDialog from "../exportDialog/exportDialog";
import Layout from "../layout/layout";
import Editor from "../editor/editor";

import style from "./main.module.scss";


export default function Main() {

    const {id} = useParams();

    const dispatch = useDispatch();
    const data = useSelector(state => state.data);

    const [error, setError] = useState(null);

    const [exporting, setExporting] = useState(false);
    const [exportKey, setExportKey] = useState(null);

    useEffect(() => {
        if ( data != null ) {
            return;
        }

        if ( id == null ) {
            dispatch(set_data({
                kingdoms: {}
            }));
            return;
        }

        let mounted = true;
        fetch(process.env.REACT_APP_BYTEBIN_URI + "/" + id).then(res => {
            if ( !res.ok ) {
                throw new Error(res.status + " " + res.statusText);
            }
            if ( !mounted ) {
                return;
            }
            return res.json();
        }).then(json => {
            dispatch(set_data(json));
        }).catch(err => {
            setError(err.message);
        });

        return () => {
            mounted = false;
        }
    }, [id, data, dispatch]);

    if ( error ) {
        return (
            <Container maxWidth={"lg"} className={style.error}>
                <br/>
                <strong>{error}</strong>
            </Container>
        )
    }

    if ( data == null ) {
        return null;
    }

    function exportData() {
        if ( exporting ) {
            return;
        }
        setExporting(true);
        fetch(process.env.REACT_APP_BYTEBIN_URI + "/", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if ( !res.ok ) {
                return;
            }
            return res.json();
        }).then(json => {
            console.log("kingdomcraft editor " + json.key);
            setExportKey(json.key);
        }).finally(() => {
            setExporting(false);
        });
    }

    return (
        <Layout menu={(
            <Button color={"secondary"} variant={"contained"}
                    disabled={exporting}
                    onClick={exportData}
            >
                {exporting ? <CircularProgress size={16} color={"primary"}/> : <span><i className="fas fa-save"/></span>}
                &nbsp;&nbsp;
                Save
            </Button>
        )}>

            <Editor/>
            { exportKey && <ExportDialog value={exportKey} onClose={() => setExportKey(null)}/> }
        </Layout>
    )
}
