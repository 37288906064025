import React, {useState} from "react";

import Alert from '@mui/material/Alert';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import {set_rank} from "../../redux/actions";
import {default as MuiTextField} from "@mui/material/TextField/TextField";
import {useDispatch, useSelector} from "react-redux";

export default function CreateRankDialog({kingdom, open, onClose, onCreate}) {

    const dispatch = useDispatch();
    const ranks = useSelector(state => state.data.kingdoms[kingdom].ranks);

    const [createRankSnackbar, setCreateRankSnackbar] = useState(null);
    const [createRankName, setCreateRankName] = useState("");

    function closeCreateRank() {
        setCreateRankName("");
        onClose();
    }

    function onCreateRank(e) {
        e.preventDefault();
        if (ranks) {
            for (let rank of Object.keys(ranks)) {
                if (rank.toLowerCase() === createRankName.toLowerCase()) {
                    setCreateRankSnackbar(
                        <Alert onClose={() => setCreateRankSnackbar(null)} severity="error">
                            A rank with that name already exists!
                        </Alert>
                    )
                    return;
                }
            }
        }

        setCreateRankSnackbar(
            <Alert onClose={() => setCreateRankSnackbar(null)} severity="success">
                Created rank {createRankName} in kingdom {kingdom}!
            </Alert>
        );

        dispatch(set_rank(kingdom, createRankName, {}));
        onCreate(kingdom, createRankName);
        closeCreateRank();
    }

    return <>
        <Dialog onClose={closeCreateRank} open={open}>
            <DialogTitle>Create rank</DialogTitle>
            <DialogContent>
                <form onSubmit={onCreateRank}>
                    <DialogContentText>
                        Enter the rank's name.
                    </DialogContentText>
                    <MuiTextField autoFocus margin="dense" type="text" variant={"outlined"} fullWidth
                                  value={createRankName}
                                  onChange={(e) => setCreateRankName(e.target.value.replace(/[^0-9a-z]/gi, ""))}/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeCreateRank} color="primary">
                    Cancel
                </Button>
                <Button onClick={onCreateRank} color="primary" disabled={createRankName === ""}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={!!createRankSnackbar} autoHideDuration={6000}
                  onClose={() => setCreateRankSnackbar(null)} anchorOrigin={{vertical: "top", horizontal: "center"}}>
            {createRankSnackbar}
        </Snackbar>
    </>;
}
