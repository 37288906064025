import React, {useState} from "react";
import {useDispatch} from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import {delete_rank} from "../../redux/actions";
import Alert from '@mui/material/Alert';
import Snackbar from "@mui/material/Snackbar";

export default function DeleteRankDialog({ kingdom, rank, open, onClose }) {

    const dispatch = useDispatch();

    const [deleteSnackbar, setDeleteSnackbar] = useState(null);

    function onDeleteRank() {
        setDeleteSnackbar(
            <Alert onClose={() => setDeleteSnackbar(null)} severity="success">
                Deleted the rank {rank} in kingdm {kingdom}!
            </Alert>
        );

        dispatch(delete_rank(kingdom, rank));
        onClose();
    }

    if ( !open ) {
        return null;
    }

    return (
        <>
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>Delete rank</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete <strong>{rank}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onDeleteRank}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={!!deleteSnackbar} autoHideDuration={6000} onClose={() => setDeleteSnackbar(null)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                {deleteSnackbar}
            </Snackbar>
        </>
    )
}
