import React from "react";
import {createStore} from "redux";
import {Provider} from "react-redux";

import reducers from "./reducers";

export const store = createStore(reducers, {
    data: null
});

export default function ReduxWrapper(props) {
    const {children} = props;

    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}
