import React, {useState} from "react";

import Alert from '@mui/material/Alert';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import {set_rank} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function CloneRankDialog({kingdom, rank, open, onClose, onClone}) {

    const dispatch = useDispatch();
    const kingdoms = useSelector(state => state.data.kingdoms);

    const [cloneRankSnackbar, setCloneRankSnackbar] = useState(null);
    const [selectedKingdoms, setSelectedKingdoms] = useState([]);

    if ( !kingdom || !rank ) {
        return null;
    }

    function closeCloneRank() {
        setSelectedKingdoms([]);
        onClose();
    }

    function onCloneRank() {
        setCloneRankSnackbar(
            <Alert onClose={() => setCloneRankSnackbar(null)} severity="success">
                Cloned rank <strong>{rank}</strong> to <strong>{selectedKingdoms.length}</strong> kingdoms!
            </Alert>
        )

        for ( let target of selectedKingdoms ) {
            dispatch(set_rank(target, rank, JSON.parse(JSON.stringify(kingdoms[kingdom].ranks[rank]))));
        }

        onClone(selectedKingdoms[0]);
        closeCloneRank()
    }

    function toggleAll() {
        if ( selectedKingdoms.length === Object.keys(kingdoms).length-1 ) {
            setSelectedKingdoms([]);
            return;
        }

        const result = [];
        for ( let kd of Object.keys(kingdoms) ) {
            if ( kd !== kingdom ) {
                result.push(kd);
            }
        }
        setSelectedKingdoms(result);
    }

    function toggleKingdom(kingdom) {
        const index = selectedKingdoms.indexOf(kingdom);
        if ( index === -1 ) {
            setSelectedKingdoms([...selectedKingdoms, kingdom]);
            return;
        }

        selectedKingdoms.splice(index, 1);
        setSelectedKingdoms([...selectedKingdoms]);
    }

    return (
        <>
            <Dialog onClose={closeCloneRank} open={open}>
                <DialogTitle>Copy rank</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the target kingdoms
                    </DialogContentText>
                    <Grid container>
                        <FormControlLabel label={"All"}
                                          control={<Checkbox color={"primary"} checked={selectedKingdoms.length === Object.keys(kingdoms).length-1} onChange={() => toggleAll()} />}
                        />
                        {Object.keys(kingdoms).map((val, i) => {
                            if ( val === kingdom ) {
                                return null;
                            }
                            return (
                                <Grid item key={i}>
                                    <FormControlLabel label={val}
                                                      control={<Checkbox checked={selectedKingdoms.indexOf(val) !== -1} onChange={() => toggleKingdom(val)} />}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCloneRank} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onCloneRank} color="primary" disabled={selectedKingdoms.length === 0}>
                        Clone
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={!!cloneRankSnackbar} autoHideDuration={6000}
                      onClose={() => setCloneRankSnackbar(null)} anchorOrigin={{vertical: "top", horizontal: "center"}}>
                {cloneRankSnackbar}
            </Snackbar>
        </>
    )
}
