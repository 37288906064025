import React from "react";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";

import {set_kingdom_attribute, set_kingdom_property} from "../../redux/actions";
import TextField from "./fields/textField";
import BooleanField from "./fields/booleanField";
import NumberField from "./fields/numberField";
import SelectField from "./fields/selectField";
import OldAttributes from "./attributes/OldAttributes";
import Attributes from "./attributes/Attributes";

export default function KingdomEditForm({kingdom, data}) {

    const dispatch = useDispatch();
    const kingdomAttributes = useSelector(state => state.data.kingdomAttributes);

    let attributes = null;
    if (kingdomAttributes != null && Object.keys(kingdomAttributes).length > 0) {
        const first = Object.keys(kingdomAttributes)[0];
        if ( typeof kingdomAttributes[first] == "string") {
            attributes = <OldAttributes attributes={kingdomAttributes} data={data}
                                        set_attribute={(key, val) => dispatch(set_kingdom_attribute(kingdom, key, val))}
            />
        } else {
            attributes = <Attributes attributes={kingdomAttributes} data={data}
                                     set_attribute={(key, val) => dispatch(set_kingdom_attribute(kingdom, key, val))}
            />
        }
    }

    return (
        <>
            <Typography component={"h1"} variant={"h4"}>
                Edit kingdom
            </Typography>
            <br/>

            <TextField name={"Display"} helpUrl={"/starter/kingdoms#display"}
                       value={data.display ? data.display : ""}
                       onChange={(value) => dispatch(set_kingdom_property(kingdom, "display", value))}/>

            <TextField name={"Prefix"} helpUrl={"/starter/kingdoms#prefix"}
                       value={data.prefix ? data.prefix : ""}
                       onChange={(value) => dispatch(set_kingdom_property(kingdom, "prefix", value))}/>

            <TextField name={"Suffix"} helpUrl={"/starter/kingdoms#suffix"}
                       value={data.suffix ? data.suffix : ""}
                       onChange={(value) => dispatch(set_kingdom_property(kingdom, "suffix", value))}/>

            <BooleanField name={"Invite only"} helpUrl={"/starter/kingdoms#invite-only"}
                          value={!!data["invite-only"]}
                          onChange={(value) => dispatch(set_kingdom_property(kingdom, "invite-only", value))}/>

            <NumberField name={"Max. members"} helpUrl={"/starter/kingdoms#max-members"}
                         value={data["max-members"] ? data["max-members"] : ""}
                         onChange={(value) => dispatch(set_kingdom_property(kingdom, "max-members", value))}/>

            <SelectField name={"Default rank"} helpUrl={"/starter/kingdoms#defaultrank"}
                         value={data.defaultrank ? data.defaultrank : ""}
                         values={data.ranks ? Object.keys(data.ranks) : []}
                         onChange={(value) => dispatch(set_kingdom_property(kingdom, "defaultrank", value))}/>

            {attributes}
        </>
    )

}
