import React from "react";
import {default as MuiTextField} from "@mui/material/TextField";
import FieldLayout from "./FieldLayout";

export default function TextField({ name, value, helpUrl, onChange }) {

    return (
        <FieldLayout helpUrl={helpUrl} name={name}>
            <MuiTextField
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
            />
        </FieldLayout>
    );
}
