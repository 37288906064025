import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FieldLayout from "./FieldLayout";

export default function BooleanField({ name, value, helpUrl, onChange }) {

    return (
        <FieldLayout helpUrl={helpUrl} name={name}>
            <FormControlLabel label="" control={
                <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
            }/>
        </FieldLayout>
    );
}
