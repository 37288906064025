import React from 'react';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function Layout({ children, menu }) {

    return <>
        <AppBar position="fixed">
            <Toolbar>
                <Typography component={"h1"} variant={"h5"}>
                    KingdomCraft Editor
                </Typography>
                <div style={{flexGrow: '1'}}/>
                {menu}
            </Toolbar>
        </AppBar>
        <Toolbar/>
        <br/>

        {children}
    </>;
}
