import {
    DELETE_KINGDOM,
    DELETE_RANK,
    SET_DATA,
    SET_KINGDOM,
    SET_KINGDOM_ATTRIBUTE,
    SET_KINGDOM_PROPERTY,
    SET_RANK,
    SET_RANK_ATTRIBUTE, SET_RANK_PROPERTY
} from "./reducers";

export function set_data(data) {
    return {
        type: SET_DATA,
        data: data
    }
}

export function set_kingdom(kingdom, data) {
    return {
        type: SET_KINGDOM,
        kingdom: kingdom,
        data: data
    }
}
export function delete_kingdom(kingdom) {
    return {
        type: DELETE_KINGDOM,
        kingdom: kingdom
    }
}
export function set_kingdom_attribute(kingdom, attribute, data) {
    return {
        type: SET_KINGDOM_ATTRIBUTE,
        kingdom: kingdom,
        attribute: attribute,
        data: data
    }
}
export function set_kingdom_property(kingdom, property, data) {
    return {
        type: SET_KINGDOM_PROPERTY,
        kingdom: kingdom,
        property: property,
        data: data
    }
}
export function set_rank(kingdom, rank, data) {
    return {
        type: SET_RANK,
        kingdom: kingdom,
        rank: rank,
        data: data
    }
}
export function delete_rank(kingdom, rank) {
    return {
        type: DELETE_RANK,
        kingdom: kingdom,
        rank: rank
    }
}
export function set_rank_attribute(kingdom, rank, attribute, data) {
    return {
        type: SET_RANK_ATTRIBUTE,
        kingdom: kingdom,
        rank: rank,
        attribute: attribute,
        data: data
    }
}
export function set_rank_property(kingdom, rank, property, data) {
    return {
        type: SET_RANK_PROPERTY,
        kingdom: kingdom,
        rank: rank,
        property: property,
        data: data
    }
}

